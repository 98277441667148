import {
  Article,
  GPTWidget,
  GPTWidgetResult,
  Language,
  RiskLevel,
} from "@prisma/client";
import { QueryParamType } from "@ui/hooks/query-param";
import { API, Method } from "@util/query";
import { localStorage } from "@util/window";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

export type FbEvent = {
  cover: {
    offset_x: number;
    offset_y: number;
    source: string;
    id: string;
  };
  start_time: string;
  attending_count: string;
  end_time: string;
  description: string;
  timezone: string;
  category: string;
  name: string;
  online_event_format: string;
  type: string;
  place?: {
    name: string;
    location: {
      city: string;
      country: string;
      latitude: number;
      longitude: number;
    };
    id: string;
  };
  id: string;
};
export type FbGroup = {
  cover: {
    cover_id: string;
    offset_x: number;
    offset_y: number;
    source: string;
    id: string;
  };
  member_count: number;
  country: string;
  name: string;
  id: string;
};
export const useSendSlackMutation = () =>
  useMutation(API._mutate(Method.POST, `/slack/appmetrics`));
export const useLocalStorage = (key: string, initialValue: any) => {
  const [value, setValue] = useState<any>(initialValue);

  useEffect(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) setValue(JSON.parse(storedValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    value,
    (value: any) => {
      setValue(value);
      localStorage.setItem(key, JSON.stringify(value));
    },
  ];
};
export const useLocalStorageNotInitial = (key: string, initialValue: any) => {
  const [value, setValue] = useState<any>();

  useEffect(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) setValue(JSON.parse(storedValue));
    else setValue(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    value,
    (value: any) => {
      setValue(value);
      localStorage.setItem(key, JSON.stringify(value));
    },
  ];
};
export const useDownloadAppSource = () =>
  useQuery(["useDownloadAppSource"], API._query(Method.GET, `app/download`));

export const useGPTActiveWidgets = () =>
  useQuery<GPTWidget[]>(
    ["useGPTActiveWidgets"],
    API._query(Method.GET, `widget/active`)
  );

export const useGPTWidgetList = () =>
  useQuery<GPTWidget[]>(
    ["gptWidgetList"],
    API._query(Method.GET, `widget/gpt`)
  );

export enum GPTParamType {
  int = "int",
  sex = "sex",
  text = "text",
  select = "select",
  condition = "condition",
}

export type GPTParam = {
  type: GPTParamType;
  name: string;
  transKey: string;
  transRequired?: boolean;
  values: string;
  required?: boolean;
  test?: string;
};

export type GPTWidgetData = {
  name: string;
  prompt: string;
  params: GPTParam[];
  riskLevel: RiskLevel;
  gptReqType: string;
  model: string;
  temperature: number;
  maxTokens: number;
  image: string;
  sticker: string;
  titleColor: string;
  backColor: string;
  order: number;
  active: boolean;
};

export const useGPTRead = (name: string) =>
  useQuery<GPTWidget>(
    ["gptRead", name],
    API._query(Method.GET, `widget/gpt/${name}`),
    { enabled: !!name }
  );

export const useGPTCreate = () =>
  useMutation(API._mutate(Method.POST, `widget/gpt`));

export const useGPTUpdate = () =>
  useMutation(API._mutate(Method.PUT, `widget/gpt`));

export type GPTWidgetRequest = {
  name: string;
  lang: Language;
  params: Record<string, string>;
};

export const useGPTRequest = () =>
  useMutation(API._mutate(Method.POST, `widget/result`));

export const useGPTResult = (id: string) =>
  useQuery<GPTWidgetResult & { widget: GPTWidget }>(
    ["gptWidgetResult", id],
    API._query(Method.GET, `widget/result/${id}`),
    { enabled: !!id, retry: false }
  );

export type GPTTestData = {
  lang: Language;
  prompt: string;
  params: GPTParam[];
  values: Record<string, string>;
  gptReqType: string;
  model: string;
  temperature: number;
  maxTokens: number;
};

export const useGPTTest = () =>
  useMutation(API._mutate(Method.POST, `widget/test`));

export const useConditionSearch = (
  lang: string,
  name: string,
  disabled: boolean
) =>
  useQuery(
    ["conditionSearch", lang, name],
    API._query(Method.POST, `widget/concept/condition`, {}, { name, lang }),
    { enabled: !!name && !!lang && !disabled, retry: false }
  );

export const useFacebookEvent = (country: string) =>
  useQuery<FbEvent[]>(
    ["fbEvent"],
    API._query(Method.GET, `widget/facebook/${country}/fbevent`, {}),
    { retry: false }
  );
export const useFacebookGroups = (country: string) =>
  useQuery<FbGroup>(
    ["fbGroup"],
    API._query(Method.GET, `widget/facebook/${country}/fbgroup`, {}),
    { retry: false }
  );

export const useFetchArticle = () =>
  useMutation(API._mutate(Method.POST, `widget/article/fetch`));

export const useSummarizeArticle = () =>
  useMutation(API._mutate(Method.POST, `widget/article/summarize`));

export const useTranslateArticle = () =>
  useMutation(API._mutate(Method.POST, `widget/article/translate`));

export const useCreateArticle = () =>
  useMutation(API._mutate(Method.POST, `widget/article`));

export const useUpdateArticle = () =>
  useMutation(API._mutate(Method.PUT, `widget/article`));

export const useUpdateArticleTrans = () =>
  useMutation(API._mutate(Method.PUT, `widget/article/translation`));

export const useDeleteArticle = () =>
  useMutation(API._mutate(Method.POST, `widget/article/delete`));

export const useApproveArticle = () =>
  useMutation(API._mutate(Method.PUT, `widget/article/approve`));

export const useFeaturedArticles = (country: string, lang: string) =>
  useQuery(
    ["featuredArticles", country, lang],
    API._query(
      Method.GET,
      `widget/article/featured?country=${country}&lang=${lang}`
    )
  );

export const useListArticles = (filter: QueryParamType) =>
  useQuery(
    ["listArticles", filter.size, filter.page, filter.source, filter.text],
    API._query(Method.POST, `widget/article/list`, {}, filter),
    { enabled: !!filter }
  );

export const useViewArticles = (filter: QueryParamType) =>
  useQuery(
    ["viewArticles", filter.size, filter.page, filter.source],
    API._query(Method.POST, `widget/article/view`, {}, filter),
    { enabled: !!filter }
  );

export const useArticle = (id?: Article["id"]) =>
  useQuery(
    ["article", id],
    API._query(Method.GET, `widget/article/get/${id}`),
    { enabled: !!id }
  );

export const useGPTModels = () =>
  useQuery(["useGPTModels"], API._query(Method.GET, `widget/gpt/models`));

export const useFindDoctorDesc = () =>
  useMutation(API._mutate(Method.POST, `widget/find-doctor`));

export const useFindDoctorDoctors = () =>
  useMutation(API._mutate(Method.PUT, `widget/find-doctor`));

export const useFindDoctorList = (lang: Language) =>
  useQuery(
    ["useFindDoctorList", lang],
    API._query(Method.GET, `widget/find-doctor/specialties/${lang}`)
  );
