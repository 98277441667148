export const colors = {
  black: "#171717",
  offBlack: "#000000",
  white: "#F7F7FF",
  offWhite: "#FCFDFF",
  main: {
    25: "#EFF0F9",
    50: "#B9BDDD",
    100: "#A4A9D2",
    200: "#8F95C8",
    300: "#7A81BE",
    400: "#656DB3",
    500: "#5059A9",
    600: "#464E95",
    700: "#3D4480",
    800: "#33396C",
    900: "#2A2E58",
  },
  nourishBlue: {
    500: "#2692BC",
  },
  nourishGreen: {
    100: "#E9EEED",
    300: "#8CD8CC",
    400: "#53C6B3",
    500: "#16B59C",
    600: "#187E6E",
    700: "#0E8B77",
  },
  whiteAlpha: {
    50: "rgba(245, 247, 255, 0.04)",
    100: "rgba(245, 247, 255, 0.06)",
    200: "rgba(245, 247, 255, 0.08)",
    300: "rgba(245, 247, 255, 0.16)",
    400: "rgba(245, 247, 255, 0.24)",
    500: "rgba(245, 247, 255, 0.36)",
    600: "rgba(245, 247, 255, 0.48)",
    700: "rgba(245, 247, 255, 0.64)",
    800: "rgba(245, 247, 255, 0.80)",
    900: "rgba(245, 247, 255, 0.92)",
    1000: "rgba(245, 247, 255, 0.99)",
  },
  blackAlpha: {
    50: "rgba(23, 23, 23, 0.04)",
    100: "rgba(23, 23, 23, 0.06)",
    200: "rgba(23, 23, 23, 0.08)",
    300: "rgba(23, 23, 23, 0.16)",
    400: "rgba(23, 23, 23, 0.24)",
    500: "rgba(23, 23, 23, 0.36)",
    600: "rgba(23, 23, 23, 0.48)",
    700: "rgba(23, 23, 23, 0.64)",
    800: "rgba(23, 23, 23, 0.80)",
    900: "rgba(23, 23, 23, 0.92)",
  },
  darkblue: {
    50: "#EAECF4",
    100: "#E0E2E9",
    200: "#CBCCD3",
    300: "#B5B7BC",
    400: "#A0A1A6",
    500: "#8B8C90",
    600: "#434e70",
    700: "#394360",
    800: "#303950",
    850: "#262e40",
    900: "#1d2330",
  },
  gray: {
    50: "#EAECF4",
    100: "#E0E2E9",
    200: "#CBCCD3",
    300: "#B5B7BC",
    400: "#A0A1A6",
    500: "#8B8C90",
    600: "#76777A",
    700: "#616164",
    800: "#4B4C4D",
    850: "#39393A",
    900: "#212121",
  },
  red: {
    50: "#F4CBDB",
    100: "#F3B0C5",
    200: "#F296B0",
    300: "#F17B9A",
    400: "#F06185",
    500: "#EF466F",
    600: "#D04063",
    700: "#B13B58",
    800: "#92354C",
    900: "#733040",
  },
  orange: {
    10: "#F9F4F3",
    25: "#F5F5F9",
    50: "#E6D0CB",
    100: "#DDB8AD",
    200: "#D5A18E",
    300: "#CC896F",
    400: "#C37250",
    500: "#BA5A31",
    600: "#A3512F",
    700: "#8C492C",
    800: "#75402A",
    900: "#5E3827",
  },
  yellow: {
    25: "#FFFBF3",
    50: "#F8EED9",
    100: "#F9E8C2",
    200: "#FBE2AB",
    300: "#FCDD94",
    400: "#FED77D",
    500: "#FFD166",
    600: "#DEB75C",
    700: "#BD9C51",
    800: "#9B8247",
    900: "#7A673D",
  },
  green: {
    10: "#F0F6F6",
    25: "#ECF2F6",
    50: "#CBE1DB",
    100: "#B2D4C6",
    200: "#99C7B0",
    300: "#7FB99B",
    400: "#66AC85",
    500: "#4D9F70",
    600: "#468C64",
    700: "#407958",
    800: "#39664C",
    900: "#335341",
  },
  teal: {
    50: "#C2DCE7",
    100: "#A4CCD9",
    200: "#86BCCA",
    300: "#68ACBC",
    400: "#499CAD",
    500: "#2B8C9F",
    600: "#297C8C",
    700: "#286C79",
    800: "#265C66",
    900: "#254C53",
  },
  blue: {
    10: "#EAF4FF",
    25: "#F2F9FF",
    50: "#C2EAFF",
    100: "#A3E2FF",
    200: "#84DAFF",
    300: "#66D2FF",
    400: "#47CAFF",
    500: "#28C2FF",
    600: "#27AADE",
    700: "#2692BD",
    800: "#257A9B",
    900: "#24617A",
  },
  cyan: {
    25: "#E8F6FF",
    50: "#CCF4FF",
    100: "#B3F1FF",
    200: "#9AEFFF",
    300: "#81EDFF",
    400: "#69EBFF",
    500: "#50E9FF",
    600: "#49CBDE",
    700: "#42ADBD",
    800: "#3B8F9B",
    900: "#34717A",
  },
  purple: {
    50: "#D7D0E0",
    100: "#C5B8CD",
    200: "#B4A1BA",
    300: "#A289A8",
    400: "#907295",
    500: "#7E5A82",
    600: "#705173",
    700: "#624965",
    800: "#544056",
    900: "#463848",
  },
  pink: {
    25: "#FDF7F5",
    50: "#F5DCD2",
    100: "#F4D4CD",
    200: "#F4C4C2",
    300: "#F3B5B8",
    400: "#F3A5AD",
    500: "#F295A3",
    600: "#D38490",
    700: "#B3727C",
    800: "#946168",
    900: "#754F55",
  },
  welcome: {
    1: "#FFA34F",
    2: "#F8627F",
  },
};
