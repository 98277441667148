export enum MedervaLayoutType {
  MedervaLayout,
  AvanteLayout,
  MobileAppLayout,
}

export const OpenAIReqType: Record<string, string> = {
  Complete: "complete",
  Chat: "chat",
  Edit: "edit",
};
