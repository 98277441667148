import {
  useEffect,
  ReactNode,
  ComponentType,
  useCallback,
  useState,
} from "react";
import { useRouter } from "next/router";
import { useAuth } from "./AuthProvider";
import { getName } from "@util/language";
import { Language, UserRole } from "@prisma/client";
import { identify } from "@util/analytics";
import { toaster } from "@ui/index";
import useTranslation from "next-translate/useTranslation";
import { useCookies } from "react-cookie";
import {
  useLocalStorage,
  useLocalStorageNotInitial,
} from "@lib/widget/data/hooks";

type RequireLoginProps = {
  redirectBack?: boolean;
  children: ReactNode;
};
// Component wrapper for requiring login
// Usage:
//   <RequireLogin>{page}</RequireLogin>;
export const RequireLogin = ({
  redirectBack = true,
  children,
}: RequireLoginProps) => {
  const router = useRouter();
  const { t: te } = useTranslation("error");
  const [loggedIn, setLoggedIn] = useState(false);
  const { isLoggedIn, status, user, error } = useAuth();
  const [cookie] = useCookies(["lang"]);

  useEffect(() => {
    if (user) {
      const info = {
        displayName: getName(user.profile, Language.en),
        email: user.email ?? undefined,
        phone: user.phoneNumber ?? undefined,
        age: (user.quiz as any)?.age ?? undefined,
      };
      identify(user.id, info);
    }
  }, [user]);
  const [nourishVersion, setNourishVersion] = useLocalStorageNotInitial(
    "nourishVersion",
    0
  );

  const redirect = useCallback(
    (pathname: string) => {
      if (router.query.version) {
        setNourishVersion(parseInt(router.query.version as string));
      }
      if (router.pathname !== pathname)
        router.replace(
          {
            pathname,
            query: { redirectTo: redirectBack ? router.asPath : "" },
          },
          "",
          cookie.lang ? { locale: cookie.lang } : {}
        );
    },
    [router, redirectBack, cookie, setNourishVersion]
  );

  useEffect(() => {
    if (status === "error" && error?.statusCode === 401) {
      if (loggedIn) toaster.error(te("session-expired"));
      redirect("/auth/login");
    } else if (
      status === "success" &&
      user &&
      (!user.profile ||
        (user.role === UserRole.LOCAL_DOCTOR &&
          user.profile.specialistTypes &&
          user.profile.specialistTypes.length === 0))
    ) {
      redirect("/account/personal-details");
    }
    if (isLoggedIn) setLoggedIn(isLoggedIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, status, user, error]);

  // Loading current user
  if (status !== "success" || !isLoggedIn) return null;

  return <>{children}</>;
};

export const withRequireLogin = (Component: ComponentType) => {
  const WithRequireLogin = (props: any) => {
    return (
      <RequireLogin>
        <Component {...props} />
      </RequireLogin>
    );
  };

  return WithRequireLogin;
};
