import { useState } from "react";
import {
  Box,
  Button,
  Container,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  Tbody,
  Td,
  Tr,
  useColorModeValue,
  VStack,
} from "@ui/index";
import { useConceptMappingQuery } from "@lib/concept/data/hooks";
import { getLang } from "@util/language";
import { IoSearch } from "react-icons/io5";
import useTranslation from "next-translate/useTranslation";

export const ConceptMappingSelector = ({
  large,
  select,
  placeHolder,
  subject,
  part,
}: {
  large: boolean;
  select: any;
  placeHolder: string;
  subject: string;
  part: string;
}) => {
  const { t: tc, lang } = useTranslation("concept");
  const [search, setSearch] = useState("");
  const inputBg = useColorModeValue("gray.100", "gray.850");
  const inputFocusBg = useColorModeValue("purple.100", "purple.900");

  const query = useConceptMappingQuery(subject, part, getLang(lang), search);

  return (
    <Box
      position={"relative"}
      flex="1"
      w="full"
      display="flex"
      flexDirection="column"
    >
      <InputGroup _focusWithin={{ color: "white" }} color={"blue.500"}>
        <Input
          fontSize={large ? "2xl" : ""}
          height={large ? 16 : 10}
          autoComplete="off"
          placeholder={placeHolder}
          type="text"
          bg={inputBg}
          borderColor={"gray.200"}
          _hover={{
            borderColor: "purple.500",
          }}
          _focus={{
            bg: inputFocusBg,
            _placeholder: { color: "white" },
            color: "white",
            borderColor: "purple.500",
          }}
          required
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <InputRightElement pt={large ? "1.5rem" : ""} pr={large ? "1rem" : ""}>
          <IoSearch size={large ? "1.5rem" : ""} />
        </InputRightElement>
      </InputGroup>
      <Container
        borderBottomLeftRadius={1.5}
        borderBottomRightRadius={1.5}
        w={"full"}
        maxW={"full"}
        mt={large ? "16" : "10"}
        zIndex="1"
        position="absolute"
        height={200}
        bg="purple.500"
        color="white"
        overflowY="scroll"
        px="0"
        py="0"
        hidden={!query.data}
      >
        <VStack w="full" textAlign="left" alignContent="left">
          {query.isLoading === true && (
            <Button isLoading={query.isLoading}></Button>
          )}
          <Table w="full" variant="simple">
            <Tbody>
              {query.isSuccess &&
                query.data.map((id: string) => (
                  <Tr
                    key={`concept-${id}`}
                    onClick={() => {
                      select(id);
                      setSearch("");
                    }}
                  >
                    <Td textAlign="left" w={"50%"}>
                      {tc(`${id}.name`)}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </VStack>
      </Container>
    </Box>
  );
};
