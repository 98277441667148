import { Box, Image, Input } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export const ImagePicker = ({
  defImgUri,
  imgFile,
  setImgFile,
}: {
  defImgUri: string;
  imgFile: File | undefined;
  setImgFile: (imgFile: File | undefined) => void;
}) => {
  const [image, setImage] = useState<string | undefined>();
  useEffect(
    () => setImage(imgFile ? URL.createObjectURL(imgFile) : undefined),
    [imgFile]
  );

  return (
    <Box>
      <Input
        type="file"
        size="sm"
        p={0.5}
        onChange={(e) => setImgFile(e.target.files?.[0] || undefined)}
        multiple={false}
        accept="image/*"
      />
      {image && <Image src={image} alt="img" />}
      {!image && defImgUri && <Image mt={4} src={defImgUri} alt="img" />}
    </Box>
  );
};
