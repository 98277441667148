import { API, Method } from "@util/query";
import { useQuery } from "react-query";
import { ConceptDomain, ConceptType, Language } from "@prisma/client";

export const useConceptSearchQuery = (
  domain: ConceptDomain,
  type: ConceptType,
  lang: Language,
  text: string
) => {
  return useQuery(
    [`useConceptSearchQuery`, domain, type, lang, text],
    API._query(Method.POST, `concept/search`, {}, { domain, type, lang, text }),
    { enabled: !!(domain && type && lang && text) }
  );
};

export const useConceptMappingQuery = (
  subject: string,
  part: string,
  lang: Language,
  text: string
) => {
  return useQuery(
    [`useConceptMappingQuery`, subject, part, lang, text],
    API._query(
      Method.POST,
      `concept/mapping`,
      {},
      { subject, part, lang, text }
    ),
    { enabled: !!(subject && lang && text) }
  );
};

export const useConceptIdsQuery = (
  domain: ConceptDomain,
  type: ConceptType
) => {
  return useQuery(
    [`useConceptSearchQuery`, domain, type],
    API._query(Method.POST, `concept/ids`, {}, { domain, type }),
    { enabled: !!(domain && type) }
  );
};

export const useConceptListQuery = (
  domain: ConceptDomain,
  type?: ConceptType
) => {
  return useQuery(
    [`useConceptListQuery`, domain, type],
    API._query(Method.GET, `concept/list/${domain}${type ? `/${type}` : ``}`),
    { enabled: !!domain }
  );
};

export const useConceptListMultiQuery = (
  domain: ConceptDomain,
  types: ConceptType[]
) => {
  return useQuery(
    [`useConceptListQuery`, domain, types],
    API._query(Method.POST, `concept/list/${domain}`, {}, { types }),
    { enabled: !!(domain && types) }
  );
};

export const useConceptDescription = (lang: string, id: string) => {
  return useQuery(
    [`useConceptDescription`, lang, id],
    API._query(Method.GET, `concept/desc/${lang}/${id}`),
    { enabled: !!(lang && id) }
  );
};
export const useConceptNextStep = (
  lang: string,
  id: string,
  specialist: string
) => {
  return useQuery(
    [`useConceptNextStep`, lang, id, specialist],
    API._query(Method.GET, `concept/desc/${lang}/${id}/${specialist}`),
    { enabled: !!(lang && id && specialist) }
  );
};
