import Head from "next/head";

const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME;
const APP_DESCRIPTION = process.env.NEXT_PUBLIC_APP_DESCRIPTION;
const APP_URL = process.env.NEXT_PUBLIC_APP_URL;
const OG_IMAGE_URL = `${APP_URL}/favicon/featured.png`;

export const SEO = ({
  title = "",
  siteTitle = APP_NAME,
  description = APP_DESCRIPTION,
}) => {
  const finalTitle = title ? `${title} | ${siteTitle}` : siteTitle;
  return (
    <Head>
      <title>{finalTitle}</title>
      <meta name="title" content={finalTitle} />
      <meta name="description" content={description} />

      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      ></meta>

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:url" content={APP_URL} />
      <meta property="og:image" content={OG_IMAGE_URL} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="628" />
      <meta property="og:locale" content="en_US" />

      <meta property="twitter:card" content="summary" />
      <meta property="twitter:creator" content="@kaiyuhsu" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta name="twitter:site" content={siteTitle} />
      <meta name="twitter:url" content={APP_URL} />
      <meta name="twitter:image" content={OG_IMAGE_URL} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <MetaPixel />
    </Head>
  );
};
const MetaPixel = () => {
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '3116452985278266');
          fbq('track', 'PageView');
          `,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=3116452985278266&ev=PageView&noscript=1"/>`,
        }}
      />
    </>
  );
};
