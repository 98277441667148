import { useCurrentUser } from "@lib/auth/data/authHooks";
import { useAnalytics } from "@util/analytics";
import { createContext, ReactNode, useContext } from "react";

const AuthContext = createContext<
  Partial<ReturnType<typeof useCurrentUser>> & {
    analytics?: ReturnType<typeof useAnalytics>;
  }
>({});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const currentUser = useCurrentUser();
  const analytics = useAnalytics();

  // [MT]: No longer required
  // useEffect(() => {
  //   const interval = setInterval(currentUser.refetch, 1 * 10 * 1000); // 1 minute
  //   return () => clearInterval(interval);
  //   //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <AuthContext.Provider value={{ ...currentUser, analytics }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
