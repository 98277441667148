import { Box, Image, Input, Skeleton, Stack } from "@chakra-ui/react";
import { uploadToS3 } from "@lib/file/data/uploadHooks";
import { useState } from "react";

export const ImageUploader = ({
  uri,
  setUri,
}: {
  uri: string;
  setUri: (uri: string) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const upload = async (imgFile: File | undefined) => {
    setLoading(true);
    const _uri = imgFile ? await uploadToS3({ file: imgFile }) : "";
    setUri(_uri);
    setLoading(false);
  };

  return !loading ? (
    <Box>
      <Input
        type="file"
        size="sm"
        p={0.5}
        onChange={(e) => upload(e.target.files?.[0] || undefined)}
        multiple={false}
        accept="image/*"
      />
      {uri && <Image src={uri} alt="img" />}
    </Box>
  ) : (
    <Stack gap={0}>
      <Skeleton my={0} h={6} />
      <Skeleton my={0} h={40} />
    </Stack>
  );
};
