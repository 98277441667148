module.exports = {
  locales: ["en", "es", "mn"],
  defaultLocale: "en",
  pages: {
    "*": [
      "common",
      "error",
      "auth",
      "account",
      "concept",
      "patient",
      "interview",
      "local-doctor",
      "nourish",
      "tips",
      "food",
      "tips",
    ],
  },
  interpolation: {
    prefix: "${",
    suffix: "}",
  },
};
