import { Country, Language, Profile } from "@prisma/client";
import { enUS, es, mn } from "date-fns/locale";

export const getSupportPhoneByCountry = (country: string) => {
  switch (country) {
    case "us":
      return "+1-800-988-5534";
    case "mn":
      return "+976-7270-2000";
    case "es":
      return "+503-6994-6053";
    case "sv":
      return "+503-6994-6053";
    case "hn":
      return "+503-6994-6053";
    case "gt":
      return "+503-6994-6053";
    default:
      return "+1-800-988-5534";
  }
};

export const getCountryLang = (country: string): Language => {
  switch (country) {
    case "us":
      return Language.en;
    case "mn":
      return Language.mn;
    case "es":
      return Language.es;
    case "sv":
      return Language.es;
    case "hn":
      return Language.es;
    case "gt":
      return Language.es;
    case "ph":
      return Language.en;
    case "hu":
      return Language.en;
    default:
      return Language.en;
  }
};

// Experimantal: [MT] For a quick implementation!
// name of the standart
export const getCountryTZ = (country: string): string => {
  switch (country) {
    case "us":
      return "America/Los_Angeles";
    case "mn":
      return "Asia/Ulaanbaatar";
    case "es":
      return "America/El_Salvador";
    case "sv":
      return "America/El_Salvador";
    case "hn":
      return "America/El_Salvador";
    case "gt":
      return "America/El_Salvador";
    case "ph":
      return "Asia/Manila";
    case "hu":
      return "Europe/Budapest";
    default:
      return "America/Los_Angeles";
  }
};

export const getLang = (lang: string): Language => {
  switch (lang) {
    case "en":
      return Language.en;
    case "es":
      return Language.es;
    case "mn":
      return Language.mn;
    default:
      return Language.en;
  }
};
export const getDatefnsLocale = (lang: string) => {
  switch (lang) {
    case "en":
      return enUS;
    case "mn":
      return mn;
    case "es":
      return es;
    default:
      return enUS;
  }
};

export const getName = (
  profile?: Pick<
    Profile,
    "firstName" | "lastName" | "latinName" | "country"
  > | null,
  lang = "en"
): string =>
  !profile
    ? ""
    : profile.latinName &&
      profile.country === Country.mn &&
      lang !== Language.mn
    ? profile.latinName
    : `${profile.firstName || ""} ${profile.lastName || ""}`;

export const getFullName = (
  profile?: Pick<Profile, "firstName" | "lastName"> | null
): string =>
  !profile ? "" : `${profile.firstName || ""} ${profile.lastName || ""}`.trim();
