import Image from "next/image";
import allergies from "./icons/allergies.svg";
import baby_0203_alt from "./icons/baby_0203_alt.svg";
import bandage_adhesive from "./icons/bandage_adhesive.svg";
import blood_cells from "./icons/blood_cells.svg";
import blood_pressure_monitor from "./icons/blood_pressure_monitor.svg";
import critical_care from "./icons/critical_care.svg";
import diabetes_measure from "./icons/diabetes_measure.svg";
import dna from "./icons/dna.svg";
import ears_nose_and_throat from "./icons/ears_nose_and_throat.svg";
import endocrinology from "./icons/endocrinology.svg";
import eye from "./icons/eye.svg";
import female_reproductive_system from "./icons/female_reproductive_system.svg";
import gastroenterology from "./icons/gastroenterology.svg";
import heart from "./icons/heart.svg";
import hospitalized from "./icons/hospitalized.svg";
import kidneys from "./icons/kidneys.svg";
import lungs from "./icons/lungs.svg";
import lymph_nodes from "./icons/lymph_nodes.svg";
import neurology from "./icons/neurology.svg";
import orthopaedics from "./icons/orthopaedics.svg";
import pediatrics from "./icons/pediatrics.svg";
import poison from "./icons/poison.svg";
import psychology from "./icons/psychology.svg";
import rheumatology from "./icons/rheumatology.svg";
import rural_post_alt from "./icons/rural_post_alt.svg";
import sling from "./icons/sling.svg";
import stethoscope from "./icons/stethoscope.svg";
import sti from "./icons/sti.svg";
import surgical_sterilization from "./icons/surgical_sterilization.svg";
import tooth from "./icons/tooth.svg";
import urology from "./icons/urology.svg";
import virus from "./icons/virus.svg";
import xray from "./icons/xray.svg";

export const iconNames: { [key: string]: string } = {
  Allergist: allergies,
  Allergologist: allergies,
  Allergology: allergies,
  Anesthesiologist: critical_care,
  Anesthesiology: critical_care,
  Angiologist: lymph_nodes,
  Angiology: lymph_nodes,
  Cardiologist: heart,
  Cardiology: heart,
  Dentist: tooth,
  Dentistry: tooth,
  Dermatologist: bandage_adhesive,
  Dermatology: bandage_adhesive,
  Diabetologist: diabetes_measure,
  Diabetology: diabetes_measure,
  Endocrinologist: endocrinology,
  Endocrinology: endocrinology,
  "ENT doctor": ears_nose_and_throat,
  "Family Medicine": stethoscope,
  Gastroenterologist: gastroenterology,
  Gastroenterology: gastroenterology,
  "General Practitioner": stethoscope,
  Genetics: dna,
  Genomics: dna,
  Gynecologist: female_reproductive_system,
  Gynecology: female_reproductive_system,
  Hematologist: blood_cells,
  Hematology: blood_cells,
  Hypertensiology: blood_pressure_monitor,
  Infectiologist: virus,
  Infectiology: virus,
  "Infectious Disease": virus,
  "Internal Diseases Specialist": virus,
  "Internal Medicine": stethoscope,
  "Internal Medicine Specialist": stethoscope,
  Neonatologist: baby_0203_alt,
  Nephrologist: kidneys,
  Nephrology: kidneys,
  Neurologist: neurology,
  Neurology: neurology,
  Oncologist: blood_cells,
  Oncology: blood_cells,
  Ophthalmologist: eye,
  Ophthalmology: eye,
  "Orthopedic Specialist": orthopaedics,
  Orthopedics: orthopaedics,
  Orthopedist: orthopaedics,
  Other: stethoscope,
  "Other specialty": stethoscope,
  "Other Specialty": stethoscope,
  Otorhinolaryngology: ears_nose_and_throat,
  "Otorhinolaryngology specialist": ears_nose_and_throat,
  "Palliative care": hospitalized,
  Pediatric: pediatrics,
  Pediatrician: pediatrics,
  Pediatrics: pediatrics,
  "Primary care": rural_post_alt,
  Psychiatrist: psychology,
  Psychiatry: psychology,
  Pulmonologist: lungs,
  Pulmonology: lungs,
  Radiologist: xray,
  Radiology: xray,
  Rheumatologist: rheumatology,
  Rheumatology: rheumatology,
  Surgeon: surgical_sterilization,
  Surgery: surgical_sterilization,
  Toxicologist: poison,
  Toxicology: poison,
  Traumatologist: sling,
  Traumatology: sling,
  Urologist: urology,
  Urology: urology,
  Venereologist: sti,
  Venereology: sti,
};

export const HealthIcon = ({
  value,
  color,
}: {
  value: string;
  color: string;
}) => (
  <Image
    src={iconNames[value]}
    style={{ width: "100%", height: "100%" }}
    color={color}
    alt={value}
  />
);
